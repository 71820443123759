<template>
  <div class="bg-white">
    <div class="row pl-0 pr-0 pl-md-3 pr-md-3">
      <div class="col-12 mb-3 pl-0 pr-0 pl-md-3 pr-md-3">
        <div class="accordion" role="tablist">
          <b-card-body class="pl-0 pr-0">
            <small>
              <div class="row">
                <div class="col-12 col-sm-6 col-md-3">
                  <label>Fornecedor</label>
                  <v-select
                    :options="fornecedores"
                    label="razao_social"
                    :itemprop="
                      (it) => ({
                        title: it.razao_social,
                        subtitle: it.fantasia,
                      })
                    "
                    v-model="filtros.fornecedor_id"
                    :reduce="
                      (v) => {
                        return v.id;
                      }
                    "
                  ></v-select>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <b-form-group label="Centro de Custo">
                    <v-select
                      :options="custos"
                      label="nome"
                      v-model="filtros.custo_id"
                      :reduce="
                        (v) => {
                          return v.cod_custo;
                        }
                      "
                    ></v-select>
                  </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <b-form-group label="Categoria / Plano de Conta">
                    <v-select
                      :options="planos"
                      label="nome"
                      v-model="filtros.plano_id"
                      :reduce="
                        (v) => {
                          return v.id;
                        }
                      "
                    ></v-select>
                  </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-3 pt-3">
                  <b-form-group label=" ">
                    <b-input
                      v-model="filtros.filtro"
                      placeholder="filtro geral"
                    />
                  </b-form-group>
                </div>
                <div class="col-12 col-sm-4 col-md-6 pt-0">
                  <b>Filtro do Periodo</b>
                  <div class="d-flex mt-">
                    <div class="d-flex mt-2 pt-">
                      <b-checkbox
                        v-model="vencidos"
                        class="mr-2"
                        @change="carregarLancamentos"
                        >Vencidos</b-checkbox
                      >
                      <b-checkbox v-model="pagos" class="mr-2" @change="filtrar"
                        >Pagos na Data</b-checkbox
                      >
                      <b-checkbox
                        v-model="vencendo"
                        class="mr-2"
                        @change="carregarLancamentos"
                        >Vencimento na Data</b-checkbox
                      >
                      <b-checkbox
                        v-model="competenciaNaData"
                        class="mr-2"
                        @change="carregarLancamentos"
                        >Competência na Data</b-checkbox
                      >
                    </div>

                    <div>
                      <!-- <b>Data</b>
                      <b-select
                        :options="[`Vencimento`, `Competência`]"
                        label="Data"
                        v-model="filtros.tipoData"
                        @change="carregarLancamentos"
                      ></b-select> -->
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-8 col-md-6 pt-0">
                  <div class="d-flex flex-wrap justify-content-end">
                    <div class="mr-2">
                      <b>Situação</b>
                      <br />
                      <b-select
                        style="max-width: 100px"
                        :options="['Todos', 'Abertos', 'Pago']"
                        label="Situação"
                        v-model="filtros.situacao"
                        @change="carregarLancamentos"
                      ></b-select>
                    </div>
                    <div class="mr-2">
                      <b>Tipo</b>
                      <br />

                      <b-select
                        style="max-width: 100px"
                        :options="['Todos', 'Fixo', 'Parcelado']"
                        label="Tipo"
                        v-model="filtros.tipo"
                        @change="carregarLancamentos"
                      ></b-select>
                    </div>
                    <div class="mr-2">
                      <b>Código</b>
                      <b-input
                        type="number"
                        style="max-width: 100px"
                        v-model="findID"
                      ></b-input>
                    </div>
                    <div class="-2">
                      <b>Código Baixa</b>
                      <b-input
                        type="number"
                        style="max-width: 100px"
                        v-model="findBaixaId"
                      ></b-input>
                    </div>
                  </div>
                </div>
              </div>
            </small>
          </b-card-body>
          <div class="d-flex">
            <b-btn
              size=""
              :block="$store.state.isMobile"
              variant="success"
              @click="() => abrirForm()"
              ><b-icon-plus /> NOVO LANÇAMENTO</b-btn
            >
            <ImportCP @reload="carregarLancamentos" />
          </div>
        </div>
      </div>
    </div>
    <div class="row pl-0 pr-0 pl-md-3 pr-md-3">
      <div class="col-6 col-sm-3 pl-0 pr-0 pl-md-3 pr-md-3">
        <b-card>
          <b>Total</b><br />
          <small>{{ totais.total | currencyMask }}</small>
        </b-card>
      </div>
      <div class="col-6 col-sm-3 pl-0 pr-0 pl-md-3 pr-md-3 text-warning">
        <b-card>
          <b>Aberto</b><br />
          <small>{{ totais.total_aberto | currencyMask }}</small>
        </b-card>
      </div>
      <div class="col-6 col-sm-3 pl-0 pr-0 pl-md-3 pr-md-3 text-info">
        <b-card>
          <b>Pago</b><br />
          <small>{{ totais.total_pago | currencyMask }}</small>
        </b-card>
      </div>
      <div class="col-6 col-sm-3 pl-0 pr-0 pl-md-3 pr-md-3 text-danger">
        <b-card>
          <b>Vencidos</b><br />
          <small>{{ totais.total_vencido | currencyMask }}</small>
        </b-card>
      </div>

      <div class="col-12 pb-2 pt-2 fixBottomIfMobile">
        <b-card>
          <div class="row">
            <div class="col-12 col-sm-6">
              <b class="mr-3">Selecionado(s): {{ selecionados.length }}</b>
              <small>
                Aberto: ({{
                  selecionados.reduce((ret, vl) => {
                    ret += vl.vl_parcela - (vl.valor_pago || 0);
                    return ret;
                  }, 0) | currencyMask
                }}) | Total: ({{
                  selecionados.reduce((ret, vl) => {
                    ret += vl.vl_parcela;
                    return ret;
                  }, 0) | currencyMask
                }})
              </small>
            </div>
            <div class="col-12 col-sm-6 text-right d-flex justify-content-end">
              <export-excel :data="relatorioExcel">
                <b-btn variant="secondary">
                  <b-icon-file-excel /> Exportar
                </b-btn>
              </export-excel>
              <b-btn
              class="ml-2"
                :block="$store.state.isMobile"
                variant="primary"
                @click="openBaixa"
                :disabled="selecionados.length <= 0"
                >Baixar Contas Selecionadas</b-btn
              >
            </div>
          </div>
        </b-card>
      </div>
      <div class="col-12 text-center pt-4" v-if="loading">
        <b-spinner /> carregando...
      </div>
      <div class="col-12 mt-3" v-if="!loading">
        <div class="text-center w-100" v-if="!lista || lista.length <= 0">
          Nenhum lançamento encontrado
        </div>
        <div v-else>
          <b-table
            small
            style="font-size: 10px"
            hover
            selectable
            stacked="sm"
            @row-selected="onRowSelected"
            ref="tbSel"
            :items="lista"
            :current-page="currentPage"
            :per-page="perPage"
            :fields="[
              { key: 'sel', label: '', class: 'text-center' },
              {
                key: 'id',
                label: 'Código',
                class: 'text-center',
                sortable: true,
              },
              {
                key: 'dt_competencia',
                label: 'Competência',
                class: 'text-center',
                sortable: true,
              },
              {
                key: 'dt_vencimento',
                label: 'Vencimento',
                class: 'text-center',
                sortable: true,
              },
              {
                key: 'parcela',
                label: 'Parcela',
                class: 'text-center',
                sortable: true,
              },
              {
                key: 'situacao',
                label: 'Situação',
                class: 'text-center',
                sortable: true,
              },
              {
                key: 'fornecedor',
                label: 'Fornecedor',
                class: 'text-left',
                sortable: true,
              },
              {
                key: 'centro',
                label: 'Centro de Custo',
                class: 'text-left',
                sortable: true,
              },
              {
                key: 'plano_conta',
                label: 'Categoria/Pl. Contas',
                class: 'text-left',
                sortable: true,
              },
              {
                key: 'vl_parcela',
                label: 'Valor',
                class: 'text-center',
                sortable: true,
              },
              { key: 'opc', label: '', class: 'text-center' },
            ]"
          >
            <template #head(sel)>
              <b-check
                v-model="selAll"
                :value="true"
                :unchecked-value="false"
              ></b-check>
            </template>
            <template #cell(sel)="row">
              <b-check
                v-model="row.item.sel"
                :value="true"
                :unchecked-value="false"
              />
            </template>
            <template #cell(dt_vencimento)="row">
              {{ row.item.dt_vencimento | moment("DD/MM/YYYY") }}
            </template>
            <template #cell(dt_competencia)="row">
              {{ row.item.dt_competencia | moment("DD/MM/YYYY") }}
            </template>
            <template #cell(parcela)="row">
              <span v-if="row.item.tipo != 'F'">
                {{ row.item.parcela }} / {{ row.item.parcelas }}</span
              >
            </template>
            <template #cell(situacao)="row">
              <div
                class="text-center text-white px-2 py-1"
                :class="{
                  'bg-success': row.item.situacao == 'T',
                  'bg-warning': row.item.situacao == 'P',
                  'bg-danger': row.item.situacao == 'A',
                }"
              >
                {{
                  row.item.situacao == "A"
                    ? "Aberto"
                    : row.item.situacao == "T"
                    ? "Pago Total"
                    : "Pago Parcial"
                }}
                <div v-if="row.item.dt_ult_pgto">
                  <small>
                    {{ row.item.dt_ult_pgto | moment("DD/MM/YYYY HH:mm") }}
                  </small>
                </div>
              </div>
            </template>
            <template #cell(vl_parcela)="row">
              {{ row.item.vl_parcela | currency }}
              <div v-if="row.item.situacao == 'P'" style="color: blue">
                <b>Pago:</b> {{ row.item.valor_pago | currency }}
              </div>
              <div v-if="row.item.situacao == 'P'" style="color: red">
                <b>Aberto:</b>
                {{ (row.item.vl_parcela - row.item.valor_pago) | currency }}
              </div>
            </template>
            <template #cell(opc)="row">
              <b-btn
                size="sm"
                variant="none"
                @click.prevent="abrirBaixas(row.item)"
                v-b-popover.hover.top="'Detalhes'"
                class="text-info"
                ><b-icon-search
              /></b-btn>
              <b-btn
                size="sm"
                variant="none"
                @click.prevent="abrirForm(row.item)"
                v-b-popover.hover.top="'EDITAR'"
                class="text-warning"
                ><b-icon-pen
              /></b-btn>
              <b-btn
                v-if="row.item.situacao != 'T'"
                size="sm"
                variant="none"
                @click.prevent="baixarParcela(row.item)"
                v-b-popover.hover.top="'BAIXAR'"
                class="text-success"
                ><b-icon-arrow-down-circle
              /></b-btn>
            </template>
          </b-table>
          <div class="d-flex">
            <b-pagination
              v-model="currentPage"
              :total-rows="lista.length"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
            <div class="ml-3 pt-2">Por Página:</div>
            <b-form-select
              style="width: 100px"
              v-model="perPage"
              :options="[10, 20, 30, 40, 50]"
              class="ml-2"
            />
          </div>
        </div>
      </div>
      <div
        v-if="$store.state.isMobile"
        style="height: 200px; display: block"
        class="w-100"
      >
        &nbsp;
      </div>
      <div
        class="col-12 pb-2 pt-2 fixBottomIfMobile"
        v-if="selecionados.length > 0"
      >
        <b-card>
          <div class="row">
            <div class="col-12 col-sm-6">
              <b class="mr-3">Selecionado(s): {{ selecionados.length }}</b>
              <small>
                Aberto: ({{
                  selecionados.reduce((ret, vl) => {
                    ret += vl.vl_parcela - (vl.valor_pago || 0);
                    return ret;
                  }, 0) | currencyMask
                }}) | Total: ({{
                  selecionados.reduce((ret, vl) => {
                    ret += vl.vl_parcela;
                    return ret;
                  }, 0) | currencyMask
                }})
              </small>
            </div>
            <div class="col-12 col-sm-6 text-right">
              <b-btn
                :block="$store.state.isMobile"
                variant="primary"
                @click="openBaixa"
                >Baixar Contas Selecionadas</b-btn
              >
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <b-modal
      id="modal-cp"
      size="xl"
      hide-footer
      :body-class="
        'modal-full ' + $store.state.isMobile ? `fixed modalNoPadding` : ''
      "
      no-enforce-focus
      title="Adicionar/editar Contas á Pagar"
      @hidden="carregarLancamentos"
    >
      <ContasPagarForm @close="closeForm" :editObj="editObj" />
    </b-modal>
    <b-modal
      id="modal-baixa"
      hide-footer
      title="Baixar Contas á Pagar"
      size="lg"
      :body-class="
        $store.state.isMobile
          ? `modal-overflow-y  modal-fixed modalNoPadding`
          : ''
      "
    >
      <ContasPagarBaixa @close="closeBaixa" :lista="listaSelecionados" />
    </b-modal>
    <b-modal
      id="modal-baixas"
      hide-footer
      size="xl"
      title="Contas Pagar - Detalhes"
      @hidden="carregarLancamentos"
    >
      <contas-pagar-detalhes
        v-if="editObj"
        :contas_pagar_id="editObj.id"
      ></contas-pagar-detalhes>
    </b-modal>
  </div>
</template>

<script>
import CustoLib from "../../libs/CentrosDeCustoLib";
import FornecedoresLib from "../../libs/FornecedoresLib";
import PlanoContasLib from "../../libs/PlanoContasLib";
import ContasPagarLib from "../../libs/ContasPagarLib";
// import ContasPagarForm from "./ContasPagar/ContasPagarForm.vue";
import ContasPagarBaixa from "./ContasPagar/ContasPagarBaixa.vue";
import moment from "moment";
// import ContasPagarBaixas from './ContasPagar/ContasPagarBaixas.vue';
import ContasPagarDetalhes from "./ContasPagar/ContasPagarDetalhes.vue";
import ImportCP from "./ContasPagar/ImportCP.vue";
export default {
  components: {
    ContasPagarForm: () => import("./ContasPagar/ContasPagarForm.vue"),
    ContasPagarBaixa,
    ContasPagarDetalhes,
    ImportCP,
  },
  props: {
    data: Object,
    empresas: Array,
  },
  mounted() {
    console.log(this.empresas, this.data);
    this.carregarLancamentos();
    this.carregaFiltros();
  },
  data() {
    return {
      loading: true,
      lista: [],
      listaRaw: [],
      fornecedores: [],
      custos: [],
      planos: [],
      filtros: {
        fornecedor_id: null,
        plano_id: null,
        custo_id: null,
        filtro: "",
        situacao: "Todos",
        tipoData: "Vencimento",
        tipo: "Todos",
      },
      totais: {
        total: 0,
        total_aberto: 0,
        total_pago: 0,
        total_vencido: 0,
      },
      selAll: false,
      editObj: null,
      listaSelecionados: [],

      vencidos: true,
      pagos: true,
      vencendo: true,
      competenciaNaData: false,
      findID: null,
      findBaixaId: null,
      pages: 10,
      perPage: 10,
      totalPages: 0,
      currentPage: 1,
    };
  },
  watch: {
    data: function () {
      this.carregarLancamentos();
    },
    empresas: function () {
      this.carregarLancamentos();
    },
    selAll: function () {
      this.checkAll();
    },
    vencidos: function () {
      this.carregarLancamentos();
    },
    vencendo: function () {
      this.carregarLancamentos();
    },
    pagos: function () {
      this.carregarLancamentos();
    },
    findID: function () {
      this.carregarLancamentos();
    },
    findBaixaId: function () {
      this.carregarLancamentos();
    },
    filtros: {
      deep: true,
      handler() {
        this.filtrar();
      },
    },
    lista: {
      deep: true,
      handler() {
        this.totalPages = Math.ceil(this.lista.length / this.perPage);
      },
    },
  },
  computed: {
    relatorioExcel: function () {
      return this.lista.map((r) => ({
        Código: r.id,
        Competência: r.dt_competencia,
        Vencimento: r.dt_vencimento,
        Parcela: r.parcela,
        Situação: r.situacao,
        Fornecedor: r.fornecedor,
        "Centro de Custo": r.centro,
        "Categoria/Plano de Conta": r.plano_conta,
        Valor: r.vl_parcela,
      }));
    },
    selecionados: function () {
      return this.lista.filter((x) => x.sel === true);
    },
    paginated() {
      return this.lista.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  methods: {
    abrirBaixas(item) {
      this.editObj = item;
      this.$bvModal.show("modal-baixas");
    },
    baixarParcela(item) {
      this.listaSelecionados = [
        {
          ...item,
          valor: this.formatFloatToReal(
            item.vl_parcela - (item.valor_pago || 0)
          ),
        },
      ];
      this.$bvModal.show("modal-baixa");
    },
    openBaixa() {
      this.listaSelecionados = this.lista
        .filter((x) => x.sel === true)
        .map((it) => {
          return {
            ...it,
            valor: this.formatFloatToReal(it.vl_parcela - (it.valor_pago || 0)),
          };
        });
      this.$bvModal.show("modal-baixa");
    },
    closeBaixa() {
      this.$bvModal.hide("modal-baixa");
      this.carregarLancamentos();
    },
    onRowSelected(rows) {
      this.selecionar(rows[0]);
      this.$refs.tbSel.clearSelected();
    },
    async filtrar() {
      this.lista = this.listaRaw.filter(
        (x) =>
          (this.filtros.fornecedor_id
            ? x.fornecedor_id === this.filtros.fornecedor_id
            : true) &&
          (this.filtros.plano_id
            ? x.plano_id === this.filtros.plano_id
            : true) &&
          (this.filtros.custo_id
            ? x.cod_custo === this.filtros.custo_id
            : true) &&
          (this.filtros.filtro && this.filtros.filtro.trim() != ""
            ? (x.fornecedor &&
                x.fornecedor
                  .toLowerCase()
                  .includes(this.filtros.filtro.trim())) ||
              x.centro.toLowerCase().includes(this.filtros.filtro.trim()) ||
              x.plano_conta
                .toLowerCase()
                .includes(this.filtros.filtro.trim()) ||
              x.vl_parcela
                .toString()
                .toLowerCase()
                .includes(this.filtros.filtro.trim())
            : true)
      );

      this.totais = await this.lista.reduce(
        (ret, vl) => {
          ret.total += vl.vl_total;
          ret.total_aberto += vl.vl_total - (vl.valor_pago || 0);
          ret.total_vencido +=
            moment(vl.dt_vencimento).isBefore(moment()) && vl.situacao != "P"
              ? vl.vl_total - (vl.valor_pago || 0)
              : 0;
          ret.total_pago += vl.valor_pago;
          return ret;
        },
        {
          total: 0,
          total_aberto: 0,
          total_pago: 0,
          total_vencido: 0,
        }
      );
      console.log("totais", this.totais);
    },
    async checkAll() {
      await this.$nextTick(() => {
        for (let element of this.lista) {
          if (element.situacao == "A" || element.situacao == "P") {
            element.sel = this.selAll;
          }
        }
      });
    },
    async carregaFiltros() {
      this.custos = await CustoLib.get();
      this.planos = (await PlanoContasLib.getPlanoContas()).data;
      this.fornecedores = await FornecedoresLib.getFornecedores();
    },
    selecionar(cp) {
      if (cp) {
        // if (cp.situacao == "A" || cp.situacao == "P")
        cp.sel = cp.sel ? false : true;
        // else cp.sel = false;
      }
    },
    closeForm() {
      this.$bvModal.hide("modal-cp");
      this.carregarLancamentos();
    },
    abrirForm(cp = null) {
      this.editObj = cp;
      this.$bvModal.show("modal-cp");
      return false;
    },
    async carregarLancamentos() {
      this.loading = true;
      this.listaRaw = (
        await ContasPagarLib.getContasPagar({
          dt_i: this.data.start,
          dt_f: this.data.end,
          cod_empresa: this.empresas.map((e) => {
            return e.cod_empresa;
          }),
          vencidos: this.vencidos,
          pagos: this.pagos,
          vencendo: this.vencendo,
          id: this.findID,
          pagamentoId: this.findBaixaId,
          situacao: this.filtros.situacao,
          tipoData: this.filtros.tipoData,
          competenciaNaData: this.competenciaNaData,
          tipo: this.filtros.tipo,
        })
      ).data.map((it) => ({ ...it, sel: false }));
      await this.$nextTick();
      this.filtrar();
      console.log("lista", this.lista);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.totalizador {
  background: #dedede;
  td {
    background-color: #f5f5f5;
  }
}
</style>
