var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row p-0 p-sm-3"},[(_vm.obj.id)?_c('div',{staticClass:"col-4 pl-0"},[_c('b-form-group',{staticStyle:{"max-width":"150px"},attrs:{"label":"Código da Baixa"}},[_c('b-input',{attrs:{"readonly":""},model:{value:(_vm.obj.id),callback:function ($$v) {_vm.$set(_vm.obj, "id", $$v)},expression:"obj.id"}})],1)],1):_vm._e(),(_vm.obj.id)?_c('div',{staticClass:"col-8 pr-1"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('label',[_vm._v("Usuário / Data")]),_c('small',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm.obj.usuario)+" | "+_vm._s(_vm._f("moment")(_vm.obj.created_at,"DD/MM/YYYY")))])])])]):_vm._e(),_c('div',{staticClass:"col-12 mt-2 pl-0 pr-0"},[_c('b-card',{attrs:{"title":"Contas á baixar","body-class":"p-2 p-sm-3"}},[_c('b-table',{ref:"tbSel",staticStyle:{"font-size":"10px"},attrs:{"small":"","hover":"","items":_vm.obj.itens,"stacked":"sm","fields":[
            { key: 'id', label: 'Código', class: 'text-center' },
            {
              key: 'dt_vencimento',
              label: 'Vencimento',
              class: 'text-center',
            },
            { key: 'parcela', label: 'Parcela', class: 'text-center' },
            { key: 'situacao', label: 'Situação', class: 'text-center' },
            { key: 'fornecedor', label: 'Fornecedor', class: 'text-left' },
            { key: 'centro', label: 'Centro de Custo', class: 'text-left' },
            {
              key: 'plano_conta',
              label: 'Categoria/Pl. Contas',
              class: 'text-left',
            },
            { key: 'vl_aberto', label: 'Valor', class: 'text-center' },
            { key: 'vl_parcela', label: 'Valor', class: 'text-center' },
            { key: 'opc', label: '', class: 'text-center' },
          ]},scopedSlots:_vm._u([{key:"head(sel)",fn:function(){return [_c('b-check',{attrs:{"value":true,"unchecked-value":false},model:{value:(_vm.selAll),callback:function ($$v) {_vm.selAll=$$v},expression:"selAll"}})]},proxy:true},{key:"cell(sel)",fn:function(row){return [_c('b-check',{attrs:{"value":true,"unchecked-value":false},model:{value:(row.item.sel),callback:function ($$v) {_vm.$set(row.item, "sel", $$v)},expression:"row.item.sel"}})]}},{key:"cell(dt_vencimento)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("moment")(row.item.dt_vencimento,"DD/MM/YYYY"))+" ")]}},{key:"cell(parcela)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.parcela)+" / "+_vm._s(row.item.parcelas)+" ")]}},{key:"cell(situacao)",fn:function(row){return [_c('div',{staticClass:"text-center text-white",class:{
                'bg-danger': row.item.situacao == 'A',
                'bg-info': row.item.situacao == 'P',
                'bg-danger': row.item.situacao == 'A',
              }},[_vm._v(" "+_vm._s(row.item.situacao == "A" ? "Aberto" : row.item.situacao == "T" ? "Pago Total" : "Pago Parcial")+" ")])]}},{key:"cell(vl_aberto)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currencyMask")((_vm.formatValorReal(row.item.vl_parcela) - _vm.formatValorReal(row.item.valor_pago))))+" "),(
                row.item.vl_parcela - row.item.valor_pago >
                _vm.formatValorReal(row.item.valor)
              )?_c('span',{staticClass:"text-danger"},[_c('br'),_vm._v(" Restante: "+_vm._s(_vm._f("currencyMask")(row.item.vl_parcela - row.item.valor_pago - _vm.formatValorReal(row.item.valor) || 0))+" ")]):_vm._e()]}},{key:"cell(vl_parcela)",fn:function(row){return [_c('b-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.moneyMask),expression:"moneyMask"}],staticClass:"text-center",staticStyle:{"font-size":"10px","min-width":"150px"},model:{value:(row.item.valor),callback:function ($$v) {_vm.$set(row.item, "valor", $$v)},expression:"row.item.valor"}})]}},{key:"cell(opc)",fn:function(row){return [_c('b-btn',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.removeLista(row.item)}}},[_c('b-icon-trash')],1)]}}])})],1)],1),_c('div',{staticClass:"col-12 mt-3 pl-0 pr-0"},[_c('b-card',{attrs:{"title":"Dados de Pagamento","body-class":"p-2 p-sm-3"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 mb-2"},[_c('select-carteira',{attrs:{"showTitle":true,"CarteiraID":_vm.obj.carteira_id},on:{"change":(ev) => (_vm.obj.carteira_id = ev)}})],1),_c('div',{staticClass:"col-12 col-sm-6 mb-2"},[_c('select-tipo-h-istorico',{attrs:{"showTitle":true,"tipoHistoricoID":_vm.obj.tipo_historico_id},on:{"change":(ev) => (_vm.obj.tipo_historico_id = ev)}})],1),_c('div',{staticClass:"col-12 col-sm-6 mb-4"},[_c('select-plano-contas',{attrs:{"showTitle":true,"planoId":_vm.obj.plano_id},on:{"change":(ev) => (_vm.obj.plano_id = ev)}})],1),_c('div',{staticClass:"col-12 col-sm-6 mb-4 pt-3"},[_c('b-checkbox',{attrs:{"switch":""},model:{value:(_vm.obj.consolidado),callback:function ($$v) {_vm.$set(_vm.obj, "consolidado", $$v)},expression:"obj.consolidado"}},[_vm._v("Consolidado? "),_c('b-icon-question-circle-fill',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                  `Irá entrar na carteira/conta já consolidado / confirmado.`
                ),expression:"\n                  `Irá entrar na carteira/conta já consolidado / confirmado.`\n                ",modifiers:{"hover":true,"top":true}}]})],1)],1),_c('div',{staticClass:"col-12 col-sm-6"},[_c('b-form-group',{attrs:{"label":"Data"}},[_c('b-input',{attrs:{"type":"date"},model:{value:(_vm.obj.created_at),callback:function ($$v) {_vm.$set(_vm.obj, "created_at", $$v)},expression:"obj.created_at"}})],1)],1),_c('div',{staticClass:"col-12 col-sm-6 text-center"},[_c('b-card',[_c('h4',[_c('label',[_vm._v("Total")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("currencyMask")(_vm.obj.valor))+" ")])])],1),_c('div',{staticClass:"col-12 mt-3"},[_c('hr'),_c('b',[_c('b-icon-upload'),_vm._v(" Anexar Documentos / Comprovantes")],1),_c('FileUpload',{attrs:{"enableTitle":false,"value":_vm.obj.anexos}})],1)])])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-right"},[_c('hr'),_c('b-btn',{staticClass:"mr-1",attrs:{"variant":"success","size":"lg","disabled":_vm.salvando},on:{"click":function($event){return _vm.baixar()}}},[(!_vm.salvando)?_c('b-icon-arrow-down-circle'):_vm._e(),(_vm.salvando)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Baixar ")],1),_c('b-btn',{attrs:{"variant":"secondary","size":"lg"},on:{"click":_vm.cancelar}},[_c('b-icon-x'),_vm._v(" Cancelar")],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }