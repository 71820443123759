<template>
  <div>
    <b-btn
      class="ml-2"
      size=""
      :block="$store.state.isMobile"
      variant="info"
      @click="abrirForm"
      ><b-icon-upload /> IMPORTAR</b-btn
    >
    <b-modal
      id="modalImport"
      title="Importar Contas Á Pagar"
      size="xl"
      :hide-footer="true"
      :ok-only="true"
      :ok-title="'Fechar'"
      :ok-variant="'danger'"
      @ok="closeModal"
    >
      <div class="row">
        <div class="col-12 my-4 py-4 px-4">
          <b-form-file
            autofocus
            v-model="file1"
            :state="Boolean(file1)"
            placeholder="Selecione um arquivo ou arraste aqui..."
            drop-placeholder="Solte o arquivo aqui..."
            style=""
            accept=".xls, .xlsx"
            @change="onFileChange"
            size="lg"
          ></b-form-file>
        </div>
        <div class="col-12">
          <div class="text-center" v-if="loading">
            <b-spinner></b-spinner>
          </div>
        </div>
        <div class="col-12 mt-4" v-if="dataRead && dataRead.length > 0">
          <div class="text-right">
            <b-btn variant="info" @click="importNow" :disabled="saving">
              <span v-if="saving">
                <b-spinner small></b-spinner> aguarde...
              </span>
              <span v-else><b-icon-upload class="mr-2" /> Importar </span>
            </b-btn>
          </div>
          <hr />
          <b-table
            :items="dataRead"
            responsive="sm"
            :fields="[
              {
                key: 'Competência',
                label: 'Competência',
                class: 'text-center',
              },
              { key: 'Vencimento', label: 'Vencimento', class: 'text-center' },
              { key: 'Pagamento', label: 'Pagamento', class: 'text-center' },
              {
                key: 'Valororiginal',
                label: 'Valor Original',
                class: 'text-center',
              },
              { key: 'Fornecedor', label: 'Fornecedor', class: 'text-left' },
              { key: 'Categoria', label: 'Categoria', class: 'text-left' },
              {
                key: 'Centrodecusto',
                label: 'Centro de Custo',
                class: 'text-left',
              },
              { key: 'Descrição', label: 'Observação', class: 'text-left' },
              { key: 'status', label: 'Status', class: 'text-center' },
            ]"
          >
            <template #cell(Competência)="row">
              <span v-if="row.item['Competência']">
                {{ row.item["Competência"] | moment("DD/MM/YYYY") }}
              </span>
            </template>

            <template #cell(Vencimento)="row">
              <span v-if="row.item['Vencimento']">
                {{ row.item["Vencimento"] | moment("DD/MM/YYYY") }}
              </span>
            </template>
            <template #cell(Pagamento)="row">
              <span v-if="row.item['Pagamento']">
                {{ row.item["Pagamento"] | moment("DD/MM/YYYY") }}
              </span>
            </template>
            <template #cell(Valororiginal)="row">
              {{ row.item["Valororiginal"] | currencyMask }}
            </template>
            <template #cell(Fornecedor)="row">
              {{ row.item["Fornecedor"] }}
              <b-icon-check-circle color="green" v-if="row.item.fornecedor" />
              <b-icon-x-circle color="red" v-if="!row.item.fornecedor" />
            </template>
            <template #cell(Categoria)="row">
              {{ row.item["Categoria"] }}
              <b-icon-check-circle color="green" v-if="row.item.palnoContas" />
              <b-icon-x-circle color="red" v-if="!row.item.palnoContas" />
            </template>
            <template #cell(Centrodecusto)="row">
              {{ row.item["Centrodecusto"] }}
              <b-icon-check-circle color="green" v-if="row.item.centroCusto" />
              <b-icon-x-circle color="red" v-if="!row.item.centroCusto" />
            </template>
            <template #cell(status)="row">
              <b-badge variant="success" v-if="row.item.status == 'OK'">
                OK
              </b-badge>
              <b-badge variant="danger" v-if="row.item.status == 'ERRO'">
                Atenção
                <div>
                  <small> Será cadastrado alguns dados </small>
                </div>
              </b-badge>
            </template>
          </b-table>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-result"
      title="Resultado da Importação"
      size="xl"
      hide-footer
    >
      <h2>Foram importados {{ resultImportacao.qtdOK }}</h2>
      <h3 class="text-danger">{{ resultImportacao.qtdErro }} com erro</h3>
      <b-tabs>
        <b-tab title="Registros OK">
          <b-table :items="resultImportacao.registrosOk"></b-table>
        </b-tab>
        <b-tab title="Registros com Erro">
          <b-table :items="resultImportacao.registroErro"></b-table>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import FornecedoresLib from "../../../libs/FornecedoresLib";
// import moment from 'moment';
import CentrosDeCustoLib from "../../../libs/CentrosDeCustoLib";
import PlanoContasLib from "../../../libs/PlanoContasLib";
import ContasPagarLib from "../../../libs/ContasPagarLib";
export default {
  components: {},
  props: {},
  mounted() {
    console.log("emp", this.$store.state.empresa_ativa);
  },
  data() {
    return {
      loadedFile: false,
      file1: null,
      dataRead: null,
      custos: [],
      fornecedores: [],
      planoContas: [],
      loading: false,
      saving: false,
      resultImportacao: {
        qtdOK: 0,
        qtdErro: 0,
        registrosOk: [],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    async importNow() {
      if (this.dataRead && this.dataRead.length > 0) {
        let res = await this.$swal.fire({
          title: "Deseja realmente importar?",
          text:
            "Será importado " +
            this.dataRead.length +
            " registros. Somente " +
            this.dataRead.filter((x) => x.status == "OK").length +
            " estão de válidos para importação. Continuar mesmo assim?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });
        console.log("res", res);
        if (!res.isConfirmed) {
          return;
        }
        this.saving = true;
        try {
          let res = await ContasPagarLib.importar(this.dataRead);
          console.log("res", res);
          if (res && res.success) {
            this.resultImportacao = res.data.data;
            this.showToast("success", "Importação processada com sucesso!");
            this.$bvModal.show("modal-result");
            this.saving = false;
            this.closeModal();
            this.dataRead = [];
            this.file1 = null;
            this.$emit("reload");
          } else {
            this.showToast("error", "Erro ao importar");
          }
        } catch (error) {
          this.showToast("error", "Erro ao importar");
        } finally {
          this.saving = false;
        }
      } else {
        this.showToast("error", "Nenhum dado para importar");
      }
    },
    async loadResources() {
      this.custos = await CentrosDeCustoLib.get();
      this.fornecedores = await FornecedoresLib.getFornecedores();
      this.planoContas = (await PlanoContasLib.getPlanoContas()).data;
      console.log("custos", this.custos);
      console.log("fornecedores", this.fornecedores);
      console.log("planoContas", this.planoContas);
    },
    abrirForm() {
      this.$bvModal.show("modalImport");
    },
    closeModal() {
      this.$bvModal.hide("modalImport");
    },
    findFornecedor(fornecedorName) {
      if (fornecedorName) {
        const normalizedFornecedorName = fornecedorName
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        return this.fornecedores.find((fornecedor) => {
          const normalizedRazaoSocial = (fornecedor.razao_social || "")
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const normalizedFantasia = (fornecedor.fantasia || "")
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          return (
            normalizedRazaoSocial.includes(normalizedFornecedorName) ||
            normalizedFantasia.includes(normalizedFornecedorName)
          );
        });
      } else {
        return null;
      }
    },
    findPlanoContas(planoDesc) {
      if (planoDesc) {
        const normalizedPlanoDesc = planoDesc
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        return this.planoContas.find((plano) => {
          const normalizedPlano = (plano.nome || "")
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          return normalizedPlano.includes(normalizedPlanoDesc);
        });
      } else {
        return null;
      }
    },
    findCentroCusto(custoDesc) {
      if (custoDesc) {
        const normalizedCustoDesc = custoDesc
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        return this.custos.find((custo) => {
          const normalizedCusto = (custo.nome || "")
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          return normalizedCusto.includes(normalizedCustoDesc);
        });
      } else {
        return null;
      }
    },
    async onFileChange(file) {
      console.log(file);
      if (
        file &&
        file.target &&
        file.target.files &&
        file.target.files.length > 0
      ) {
        this.loading = true;
        await this.loadResources();
        let result = await this.readExcelFileToJson(file.target.files[0]);
        result.forEach((item) => {
          if (item.Fornecedor) {
            item.fornecedor = this.findFornecedor(item.Fornecedor);
          }
          if (item.Categoria) {
            item.palnoContas = this.findPlanoContas(item.Categoria);
          }
          if (item.Centrodecusto) {
            item.centroCusto = this.findCentroCusto(item.Centrodecusto);
          }
          item.cod_empresa = this.$store.state.empresa_ativa.cod_empresa;
          item.status = item.palnoContas && item.centroCusto ? "OK" : "ERRO";
        });
        console.log(result);
        this.dataRead = result;
        this.showToast("success", "Leitura efetuada com sucesso!");
      } else {
        this.showToast("error", "Arquivo inválido");
      }
      this.loading = false;
    },
    readExcelFileToJson(file) {
      const XLSX = require("xlsx");
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);

          const workbook = XLSX.read(data, { type: "array", cellDates: true });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          let jsonData = XLSX.utils.sheet_to_json(worksheet, {
            defval: "",
            cellDates: true,
          });

          function normalizeData(data) {
            const normalizedData = [];
            const keys = Object.keys(data[0]);

            data.forEach((item) => {
              const normalizedItem = {};

              keys.forEach((key) => {
                const normalizedKey = key.replace(/\s/g, "");
                normalizedItem[normalizedKey] = item[key] || null;
              });

              normalizedData.push(normalizedItem);
            });

            return normalizedData;
          }
          jsonData = normalizeData(jsonData);

          resolve(jsonData);
          // Do something with the jsonData object
        };
        reader.onerror = (e) => {
          reject(e);
        };
        reader.readAsArrayBuffer(file);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
