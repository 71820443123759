<template>
  <div :id="printDiv" class="d-print-block">
    <div v-if="loading" class="text-center p-4">
      <b-spinner /><br />carregando...
    </div>
    <div
      v-if="dados && dados.deleted_at"
      class="alert alert-danger my-4 text-center"
    >
      CONTAS A PAGAR EXCLUÍDO POR {{ dados.usuario_excluiu }} em
      {{ dados.deleted_at | moment("DD/MM/YYYY HH:mm") }}
    </div>
    <div class="row" v-if="dados">
      <div class="col-12 col-lg-3 pr-lg-1 d-print-3">
        <b-card
          class="text-center"
          header="Código"
          header-class="text-white font-weight-bold"
          header-bg-variant="info"
          border-variant="info"
        >
          <h1>{{ dados.id }}</h1>
        </b-card>
      </div>
      <div class="col-12 col-lg-5 pl-lg-1 pr-lg-1">
        <b-card
          class="text-center"
          body-class="text-left"
          header="Fornecedor / Centro de Custo / Plano de Contas"
          header-class="text-whitae font-weight-bold"
          header-bg-variant="light"
          border-variant="light"
          style="font-size: 10px"
        >
          <b-icon-person-plus /> {{ dados.fornecedor }}
          <hr class="mt-1 mb-1" />
          <b-icon-house /> {{ dados.centro_custo }}
          <hr class="mt-1 mb-1" />
          <b-icon-list-nested />
          <span v-if="dados.plano_contas">
            {{ dados.plano_contas.grupo }} /
            {{ dados.plano_contas.sub_grupo }} /
            {{ dados.plano_contas.nome }}
          </span>
          <hr class="mt-1 mb-1" />
          <b-icon-chat-dots /> {{ dados.obs }}
        </b-card>
      </div>
      <div class="col-12 col-lg-4 pl-lg-1">
        <b-card
          class="text-left"
          header="Log"
          header-class="text-whitse font-weight-bold"
          header-bg-variant="light"
          border-variant="light"
          style="font-size: 10px"
        >
          <b>Cadastro</b>
          {{ dados.created_at | moment("DD/MM/YYYY HH:mm") }} -
          <small>
            {{ dados.usuario_cadastrou }}
          </small>
          <hr class="mt-1 mb-1" />
          <b>Edição</b>
          {{ dados.updated_at | moment("DD/MM/YYYY HH:mm") }} -
          <small>
            {{ dados.usuario_editou }}
          </small>
        </b-card>
      </div>

      <div class="col-12 col-lg-3 pr-lg-1">
        <b-card>
          <div class="d-flex justify-content-between">
            <b>Subtotal</b>
            {{ dados.vl_subtotal | currencyMask }}
          </div>
          <div class="d-flex justify-content-between text-danger">
            <b>Descontos</b>
            {{ dados.vl_desconto | currencyMask }}
          </div>
          <div class="d-flex justify-content-between text-info">
            <b>Acrescimos</b>
            {{ dados.vl_desconto | currencyMask }}
          </div>
          <div class="d-flex justify-content-between text-success">
            <b>Total</b>
            {{ dados.vl_total }}
          </div>
        </b-card>
      </div>
      <div class="col-12 col-lg-9 pl-lg-1">
        <b-card>
          <b-card-title> <b-icon-list /> Parcelas </b-card-title>

          <b-table
            :items="dados.parcelasObj"
            :fields="[
              { key: 'parcela', sortable: true, class: 'text-center' },
              {
                key: 'dt_vencimento',
                label: 'Vencimento',
                sortable: true,
                class: 'text-center',
              },
              {
                key: 'situacao',
                label: 'Situação',
                sortable: true,
                class: 'text-center',
              },
              {
                key: 'valor',
                label: 'Valor',
                sortable: true,
                class: 'text-center',
              },
              {
                key: 'valor_pago',
                label: 'Pago',
                sortable: true,
                class: 'text-center',
              },
              {
                key: 'dt_pago',
                label: 'Dt. Pago',
                sortable: true,
                class: 'text-center',
              },
              {
                key: 'Aberto',
                label: 'Aberto',
                sortable: true,
                class: 'text-center',
              },
              { key: 'opc', label: '', sortable: false, class: 'text-center' },
            ]"
            small
            style="font-size: 10px"
            stacked="sm"
          >
            <template #cell(dt_vencimento)="row">
              {{ row.item.dt_vencimento | moment("DD/MM/YYYY") }}
            </template>
            <template #cell(dt_pago)="row">
              <div v-if="row.item.baixas &&  row.item.baixas.filter(x=>!x.deleted_at).length > 0">
                {{ row.item.baixas[0].created_at | moment("DD/MM/YYYY") }}
                <div
                  v-if="
                    row.item.baixas[0].itens.some(
                      (d) => (d.contas_pagar_parcela_id = row.item.id)
                    ) &&
                    row.item.baixas[0].itens.find(
                      (d) => (d.contas_pagar_parcela_id = row.item.id)
                    ).dt_consolidado
                  "
                >
                  <b>Conciliado Em</b> <br />
                  {{
                    row.item.baixas[0].itens.find(
                      (d) => (d.contas_pagar_parcela_id = row.item.id)
                    ).dt_consolidado | moment("DD/MM/YYYY")
                  }}
                </div>
              </div>
            </template>
            <template #cell(situacao)="row">
              <div
                :class="`text-white 
                  ${
                    row.item.situacao == 'A'
                      ? 'bg-danger'
                      : row.item.situacao == 'P'
                      ? 'bg-warning'
                      : 'bg-success'
                  }
                    `"
              >
                {{
                  row.item.situacao == "A"
                    ? "Aberto"
                    : row.item.situacao == "P"
                    ? "Parcial"
                    : "Pago"
                }}
              </div>
            </template>
            <template #cell(Aberto)="row">
              {{
                (formatValorReal(row.item.valor) -
                  formatValorReal(row.item.valor_pago))
                  | currencyMask
              }}
            </template>
            <template #cell(opc)="row">
              <b-btn
                variant="none"
                @click="row.toggleDetails"
                size="sm"
                v-if="row.item.baixas"
                ><b-icon-list-check
              /></b-btn>
            </template>
            <template #row-details="row">
              <div class="p-2 pl-3 bg-light">
                <b-table
                  :items="
                    row.item.baixas.map((it) => ({
                      ...it,
                      _rowVariant:
                        it && it.deleted_at && it.deleted_at != '' ? 'danger' : '',
                    }))
                  "
                  :fields="[
                    { key: 'id', label: 'Código Baixa', class: 'text-center' },
                    { key: 'created_at', label: 'Data' },
                    { key: 'Conta' },
                    { key: 'Historico' },
                    { key: 'UsuarioCadastro', label: 'Usuário' },
                    { key: 'vlPago', label: 'Valor Pago' },
                    { key: 'opc', label: '' },
                  ]"
                >
                  <template #cell(id)="row">
                    {{ row.item.id }}
                    <div
                      v-if="row.item && row.item.deleted_at && row.item.deleted_at != ''"
                      class="font-weight-bold text-danger"
                    >
                      <b-icon-trash /> BAIXA EXCLUIDA
                    </div>
                  </template>
                  <template #cell(created_at)="r2">
                    {{ r2.item.created_at | moment("DD/MM/YYYY HH:mm") }}
                  </template>
                  <template #cell(vlPago)="r2">
                    {{
                      r2 && r2.item && r2.item
                        ? r2.item.itens
                            .filter(
                              (z) => z.contas_pagar_parcela_id == row.item.id
                            )
                            .reduce((ret, vl) => {
                              ret += vl.valor;
                              return ret;
                            }, 0)
                        : 0 | currencyMask
                    }}
                  </template>
                  <template #cell(opc)="r2">
                    <b-btn size="sm" variant="none" @click="openBx(r2.item.id)">
                      <b-icon-search />
                    </b-btn>
                  </template>
                </b-table>
              </div>
            </template>
          </b-table>
        </b-card>
      </div>
    </div>
    <b-tabs class="mt-4" content-class="p-lg-4" v-if="dados">
      <b-tab>
        <template #title><i class="fa fa-file-pdf" /> Anexos</template>

        <file-upload
          :value="dados.fotos"
          :enableTitle="false"
          :enableEdit="false"
        />
      </b-tab>
      <b-tab>
        <template #title
          ><i class="fa fa-file-invoice" /> Notas Fiscais</template
        >
      </b-tab>
      <b-tab>
        <template #title><i class="fa fa-barcode" /> Dados Pagamento</template>
        <div v-if="dados && dados.adicional && dados.adicional.pagamento">
          <b>Tipo de Documento</b><br />
          {{ dados.adicional.pagamento.tipo }}
          <hr />
          <b>Informação</b><br />
          {{ dados.adicional.pagamento.obs }}
        </div>
        <div v-else>Sem dados de pagamento</div>
      </b-tab>
      <b-tab v-if="dados && dados.estoque">
        <template #title><i class="fa fa-boxes" /> Estoque</template>

        <b-table
          :items="dados.estoque"
          :fields="[
            { key: 'centro_custo', label: 'Centro de Custo' },
            { key: 'created_at', label: 'Data', class: 'text-center' },
            { key: 'Prod', label: 'Produto' },
            { key: 'estoque', label: 'Quantidade', class: 'text-center' },
            { key: 'vl_total', label: 'Valor', class: 'text-center' },
          ]"
        >
          <template #cell(Prod)="row">
            {{ row.item.produto || row.item.ingrediente }}
          </template>
          <template #cell(created_at)="row">
            {{ row.item.created_at | moment("DD/MM/YYYY HH:mm") }}
          </template>
          <template #cell(vl_total)="row">
            {{ row.item.vl_total | currency }}
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
    <hr />
    <div class="row mt-4 d-print-none">
      <div class="col-12" v-if="dados">
        <b-btn
          size="lg"
          variant="danger"
          @click="excluir()"
          :disabled="dados && dados.deleted_at"
          ><b-icon-trash /> Excluir Conta</b-btn
        >
        <b-btn
          size="lg"
          variant="warning"
          class="ml-2"
          @click="editar()"
          :disabled="dados && dados.deleted_at"
          ><i class="fa fa-edit" /> Editar Conta</b-btn
        >
        <b-btn
          size="lg"
          variant="info"
          class="ml-2"
          @click="imprimir()"
          :disabled=" dados && dados.deleted_at"
          ><b-icon-printer /> Imprimir</b-btn
        >
        <b-btn
          size="lg"
          variant="success"
          class="ml-2"
          @click="baixar()"
          :disabled="dados && dados.deleted_at"
          ><b-icon-arrow-down-circle /> Baixar Parcelas</b-btn
        >
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-edit-conta"
      title="Edição Contas A Pagar"
      hide-footer
      no-enforce-focus
      :body-class="
        'modal-full ' + $store.state.isMobile ? `fixed modalNoPadding` : ''
      "
      @hidden="closeModalEdit"
    >
      <ContasPagarForm @close="closeModalEdit" :editObj="dados" />
    </b-modal>
    <b-modal
      size="xl"
      id="modal-detalhes-baixa2"
      title="Detalhes da Baixa"
      hide-footer
      @hidden="closeModalEdit"
    >
      <contas-pagar-detalhe-baixa v-if="openBaixa" :baixaId="openBaixa" />
    </b-modal>

    <b-modal
      id="modal-baixa3"
      hide-footer
      title="Baixar Contas á Pagar"
      size="lg"
      :body-class="
        $store.state.isMobile
          ? `modal-overflow-y  modal-fixed modalNoPadding`
          : ''
      "
    >
      <ContasPagarBaixa @close="closeBaixa" :lista="listaParaBaixar" />
    </b-modal>
  </div>
</template>

<script>
import ContasPagarLib from "../../../libs/ContasPagarLib";
import PrinterWindowHelper from "../../../utils/PrinterWindowHelper";
import FileUpload from "../../common/FileUpload.vue";
import ContasPagarBaixa from "./ContasPagarBaixa.vue";
import ContasPagarDetalheBaixa from "./ContasPagarDetalheBaixa.vue";
export default {
  components: {
    FileUpload,
    ContasPagarForm: () => import("./ContasPagarForm.vue"),
    ContasPagarDetalheBaixa,
    ContasPagarBaixa,
  },
  props: {
    contas_pagar_id: Number,
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      loading: false,
      dados: null,
      printDiv: "PRINTDIV",
      openBaixa: null,
      listaParaBaixar: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    closeBaixa() {
      this.carregar();
      this.$bvModal.hide("modal-baixa3");
    },
    baixar() {
      this.$bvModal.show("modal-baixa3");
    },
    openBx(bx) {
      this.openBaixa = bx;
      this.$bvModal.show("modal-detalhes-baixa2");
    },
    closeModalEdit() {
      this.$bvModal.hide("modal-edit-conta");

      this.carregar();
    },
    editar() {
      this.$bvModal.show("modal-edit-conta");
    },
    async excluir() {
      console.log("it");
      if (
        !this.dados ||
        (this.dados.parcelasObj &&
          this.dados.parcelasObj.filter(
            (o) =>
              o.baixas &&
              o.baixas.filter(
                (x) => x && (!x.deleted_at || x.deleted_at == null)
              ).length > 0
          ).length > 0)
      ) {
        this.$swal(
          "Atenção",
          "Esta conta tem parcelas baixadas. Não será possível excluir antes de excluir as baixas!",
          "error"
        );
        return;
      }

      this.$swal({
        title: "Tem certeza que deseja excluir esta conta?",
        html: "<span class='text-center'>Ao excluir não será possível recupera-la. <br/><b>Atenção!<br/>Não será possível excluir conta que houver parcela baixada!</span>",
        showCancelButton: true,
        icon: "question",
        cancelButtonText: "Não",
        confirmButtonText: "Sim, Excluir!",
        confirmButtonColor: "red",
      }).then(async (x) => {
        if (x.isConfirmed) {
          let res = await ContasPagarLib.delCP(this.dados.id);
          if (res) {
            this.$swal({
              title: res.success
                ? "Contas a Pagar Excluído!"
                : "Erro ao excluir!",
              text: res.message,
              icon: res.success ? "success" : "error",
            });
          }
          this.carregar();
        }
      });
    },
    async imprimir() {
      PrinterWindowHelper(document.getElementById(this.printDiv).innerHTML, 3);
    },
    async carregar() {
      this.printDiv = "PRINTDIV_" + Math.random();
      this.loading = true;
      try {
        let res = await ContasPagarLib.getCPById(this.contas_pagar_id);
        if (res && res.success) {
          console.log("res", res.data);
          this.dados = res.data;
          this.listaParaBaixar = res.data.parcelasObj
            .filter((x) => x.situacao != `T`)
            .map((x) => ({
              ...x,
              fornecedor: res.data.fornecedor,
              fornecedor_id: res.data.fornecedor_id,
              cod_custo: res.data.cod_custo,
              plano_conta: res.data.plano_contas
                ? res.data.plano_contas.grupo +
                  " > " +
                  res.data.plano_contas.sub_grupo +
                  " > " +
                  res.data.plano_contas.nome
                : "",
              plano_contas_id: res.data.plano_contas_id,
              centro: res.data.centro_custo,
              parcelas: res.data.parcelas,
              id: res.data.id,
              plano_id: res.data.plano_id,
              contas_pagar_parcela_id: x.id,
              //valor: this.formatValorReal(x.valor),
              //valor_pago: this.formatValorReal(x.valor_pago),
              vl_parcela: this.formatValorReal(x.valor),
              vl_aberto:
                this.formatValorReal(x.valor) -
                this.formatValorReal(x.valor_pago),
              // vl_parcela: this.formatValorReal(x.valor) - this.formatValorReal(x.valor_pago)
            }));
          console.log("listaParaBaixar", this.listaParaBaixar);
        }
      } catch (error) {
        console.log("ERROR ON GET BAIXA", error);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
