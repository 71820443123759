<template>
  <div>
    <div class="row p-0 p-sm-3">
      <div
        class="col-4 pl-0"
        v-if="obj.id"
      >
        <b-form-group
          label="Código da Baixa"
          style="max-width: 150px"
        >
          <b-input
            readonly
            v-model="obj.id"
          />
        </b-form-group>
      </div>
      <div
        class="col-8 pr-1"
        v-if="obj.id"
      >
        <div class="card">
          <div class="card-body">
            <label>Usuário / Data</label>
            <small class="ml-4"
              >{{ obj.usuario }} |
              {{ obj.created_at | moment("DD/MM/YYYY") }}</small
            >
          </div>
        </div>
      </div>

      <div class="col-12 mt-2 pl-0 pr-0">
        <b-card
          title="Contas á baixar"
          body-class="p-2 p-sm-3"
        >
          <b-table
            small
            style="font-size: 10px"
            hover
            ref="tbSel"
            :items="obj.itens"
            stacked="sm"
            :fields="[
              { key: 'id', label: 'Código', class: 'text-center' },
              {
                key: 'dt_vencimento',
                label: 'Vencimento',
                class: 'text-center',
              },
              { key: 'parcela', label: 'Parcela', class: 'text-center' },
              { key: 'situacao', label: 'Situação', class: 'text-center' },
              { key: 'fornecedor', label: 'Fornecedor', class: 'text-left' },
              { key: 'centro', label: 'Centro de Custo', class: 'text-left' },
              {
                key: 'plano_conta',
                label: 'Categoria/Pl. Contas',
                class: 'text-left',
              },
              { key: 'vl_aberto', label: 'Valor', class: 'text-center' },
              { key: 'vl_parcela', label: 'Valor', class: 'text-center' },
              { key: 'opc', label: '', class: 'text-center' },
            ]"
          >
            <template #head(sel)>
              <b-check
                v-model="selAll"
                :value="true"
                :unchecked-value="false"
              ></b-check>
            </template>
            <template #cell(sel)="row">
              <b-check
                v-model="row.item.sel"
                :value="true"
                :unchecked-value="false"
              />
            </template>
            <template #cell(dt_vencimento)="row">
              {{ row.item.dt_vencimento | moment("DD/MM/YYYY") }}
            </template>
            <template #cell(parcela)="row">
              {{ row.item.parcela }} / {{ row.item.parcelas }}
            </template>
            <template #cell(situacao)="row">
              <div
                class="text-center text-white"
                :class="{
                  'bg-danger': row.item.situacao == 'A',
                  'bg-info': row.item.situacao == 'P',
                  'bg-danger': row.item.situacao == 'A',
                }"
              >
                {{
                  row.item.situacao == "A"
                    ? "Aberto"
                    : row.item.situacao == "T"
                    ? "Pago Total"
                    : "Pago Parcial"
                }}
              </div>
            </template>
            <template #cell(vl_aberto)="row">
              {{
                (formatValorReal(row.item.vl_parcela) -
                  formatValorReal(row.item.valor_pago))
                  | currencyMask
              }}
              <span
                class="text-danger"
                v-if="
                  row.item.vl_parcela - row.item.valor_pago >
                  formatValorReal(row.item.valor)
                "
              >
                <br />
                Restante:
                {{
                  row.item.vl_parcela -
                    row.item.valor_pago -
                    formatValorReal(row.item.valor) || 0 | currencyMask
                }}
              </span>
            </template>
            <template #cell(vl_parcela)="row">
              <b-input
                v-model="row.item.valor"
                class="text-center"
                v-money="moneyMask"
                style="font-size: 10px; min-width: 150px"
              />
            </template>
            <template #cell(opc)="row">
              <b-btn
                variant="danger"
                @click="removeLista(row.item)"
                ><b-icon-trash
              /></b-btn>
            </template>
          </b-table>
        </b-card>
      </div>

      <div class="col-12 mt-3 pl-0 pr-0">
        <b-card
          title="Dados de Pagamento"
          body-class="p-2 p-sm-3"
        >
          <div class="row">
            <div class="col-12 col-sm-6 mb-2">
              <select-carteira
                :showTitle="true"
                :CarteiraID="obj.carteira_id"
                @change="(ev) => (obj.carteira_id = ev)"
              />
            </div>
            <div class="col-12 col-sm-6 mb-2">
              <select-tipo-h-istorico
                :showTitle="true"
                :tipoHistoricoID="obj.tipo_historico_id"
                @change="(ev) => (obj.tipo_historico_id = ev)"
              />
            </div>
            <div class="col-12 col-sm-6 mb-4">
              <select-plano-contas
                :showTitle="true"
                :planoId="obj.plano_id"
                @change="(ev) => (obj.plano_id = ev)"
              />
            </div>
            <div class="col-12 col-sm-6 mb-4 pt-3">
              <!-- <b-checkbox v-model="obj.multiplo" switch
                >Lançamento Múltiplo?
                <b-icon-question-circle-fill
                  v-b-popover.hover.top="
                    `Irá criar um lanaçmento bancário para cada conta baixada com estas iformações bancárias/conta de pagamento`
                  "
              /></b-checkbox> -->
              <b-checkbox
                v-model="obj.consolidado"
                switch
                >Consolidado?
                <b-icon-question-circle-fill
                  v-b-popover.hover.top="
                    `Irá entrar na carteira/conta já consolidado / confirmado.`
                  "
              /></b-checkbox>
            </div>
            <div class="col-12 col-sm-6">
              <b-form-group label="Data">
                <b-input
                  type="date"
                  v-model="obj.created_at"
                />
              </b-form-group>
            </div>

            <div class="col-12 col-sm-6 text-center">
              <b-card>
                <h4>
                  <label>Total</label><br />
                  {{ obj.valor | currencyMask }}
                </h4>
              </b-card>
            </div>
            <div class="col-12 mt-3">
              <hr />
              <b><b-icon-upload /> Anexar Documentos / Comprovantes</b>
              <FileUpload
                :enableTitle="false"
                :value="obj.anexos"
              />
            </div>
          </div>
        </b-card>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-right">
        <hr />
        <b-btn
          variant="success"
          class="mr-1"
          size="lg"
          @click="baixar()"
          :disabled="salvando"
        >
          <b-icon-arrow-down-circle v-if="!salvando" />
          <b-spinner
            small
            v-if="salvando"
          />
          Baixar
        </b-btn>
        <b-btn
          variant="secondary"
          size="lg"
          @click="cancelar"
          ><b-icon-x /> Cancelar</b-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import TipoHistorico from "../../../libs/BancoTipoHistoricoLib";
import CarteiraLib from "../../../libs/Carteiras";
import FileUpload from "../../common/FileUpload.vue";
import ContasPagarLib from "../../../libs/ContasPagarLib";
import SelectCarteira from "../Bancario/SelectCarteira.vue";
import SelectTipoHIstorico from "../Bancario/SelectTipoHIstorico.vue";
import SelectPlanoContas from "../SelectPlanoContas.vue";
import PlanoContasLib from "../../../libs/PlanoContasLib";
export default {
  components: {
    FileUpload,
    SelectCarteira,
    SelectTipoHIstorico,
    SelectPlanoContas,
  },
  props: {
    lista: Array,
  },
  mounted() {
    console.log(this.user, this.lista);
    this.obj.itens = this.lista;

    this.calculaTotal();
    console.log("lista", this.lista);
    if (this.lista && this.lista.length > 0) {
      this.obj.plano_id = this.lista[0].plano_id;
      console.log(1, this.lista[0].plano_id);
    }
  },
  data() {
    return {
      tiposHistorico: [],
      contasDisponiveis: [],

      obj: {
        id: null,
        created_at: moment().format("YYYY-MM-DD"),
        usuario: this.$store.state.auth.user.nome,
        cod_usuario: this.$store.state.auth.user.cod_usuario,
        itens: [],
        valor: 0,
        anexos: [],
        multiplo: true,
        consolidado: false,
        plano_id: null,
        tipo_historico_id: null,
      },
      salvando: false,
    };
  },
  watch: {
    lista: {
      deep: true,
      handler() {
        this.itens = this.lista;
        if (this.lista && this.lista.length > 0) {
          this.obj.plano_id = this.lista[0].plano_id;
        }
        this.calculaTotal();
      },
    },
    "obj.plano_id": async function () {
      console.log("obj.plano_id", this.obj.plano_id);
      if (this.obj.plano_id) {
        let find = await PlanoContasLib.getPlanoContas({
          id: this.obj.plano_id,
        });
        if (find) {
          if (find.data && find.data.length > 0) {
            this.obj.tipo_historico_id = find.data[0].tipo == 1 ? 1 : 2;
          }
        }
      }
    },
  },
  computed: {},
  methods: {
    async baixar() {
      let errs = [];
      if (!this.obj.tipo_historico_id) {
        errs.push("Selecione um Tipo de Movimento");
      }
      if (!this.obj.carteira_id) {
        errs.push("Selecione uma carteira");
      }
      if (!this.obj.itens || this.obj.itens.length <= 0) {
        errs.push("Informe ao menos 1 item");
      }

      if (errs.length > 0) {
        this.$swal({
          title: "Atenção",
          html: `Verifique os itens abaixo: <hr/>${errs.join("<hr/>")}<hr/>`,
          icon: "error",
        });
        return;
      }
      await this.$nextTick();
      if (this.salvando) {
        return;
      }
      this.salvando = true;
      await this.$nextTick();
      try {
        let res = await ContasPagarLib.baixarContasPagar(this.obj);
        console.log("res", res);
        if (res && res.success == true) {
          await this.$swal("Concluído", "Baixa numero " + res.data, "success");
          this.$emit("close");
        }
      } catch (error) {
        console.log("*ERRRO BAIXAR", error);
      }
      this.salvando = false;
    },
    cancelar() {
      this.$emit("close");
    },
    async calculaTotal() {
      this.obj.valor = await this.obj.itens.reduce((ret, vl) => {
        ret += this.formatValorReal(vl.valor);
        return ret;
      }, 0);
    },
    async carregarContas() {
      this.contasDisponiveis = (await CarteiraLib.getCareteiras()).data;
      console.log("cariteras", this.contasDisponiveis);
    },
    async carregarTiposHistorico() {
      this.tiposHistorico = (await TipoHistorico.getTipoHistorico()).data;
    },
    removeLista(item) {
      if (item) {
        console.log(
          this.obj.itens,
          item,
          this.obj.itens.filter(
            (vl) => `${vl.id}-${vl.parcela}` != `${item.id}-${item.parcela}`
          )
        );
        this.obj.itens = this.obj.itens.filter(
          (vl) => `${vl.id}-${vl.parcela}` != `${item.id}-${item.parcela}`
        );
        this.calculaTotal();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
